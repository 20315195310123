import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "wieVielKredit";

    const { question, answer } = TIPS_AND_TRICKS_LIST[key];

    return (
        <Layout>
            <MetaComponents title={`${question} | miracl`} description={answer} />

            <GreyHeader title={question} />

            <Article hasMarginTop hasColumns>
                <p>
                    Der Kauf einer Immobilie ist der Traum von vielen Menschen in Österreich. Dennoch haben nur die
                    wenigsten eine realistische Vorstellung davon, wie viel sie sich leisten können.
                </p>

                <p>
                    Dabei ist es im Grunde gar nicht so schwer. Der erste Schritt ist, eine einfache Haushaltsrechnung
                    zu machen. Wir zeigen dir, wie du dabei vorgehen musst:
                </p>

                <p>
                    <ol>
                        <li>
                            <b>Monatliche Einnahmen berechnen:</b> Addiere sämtliche Einkünfte wie dein Nettoeinkommen,
                            etwaige Mieteinnahmen oder Einnahmen durch Wertanlagen.
                        </li>

                        <br />

                        <li>
                            <b>Monatliche Ausgaben ermitteln:</b>. Auch dafür musst du nur die Addition beherrschen – in
                            diesem Fall die deiner Fix- und Lebenshaltungskosten wie Miet-, Betriebs- und Energiekosten,
                            Mobilitätskosten oder auch Ausgaben für Versicherungen.
                        </li>

                        <br />

                        <li>
                            <b>Im letzten Schritt</b> ziehst du die Summe deiner Ausgaben von der Gesamtheit deiner
                            Einkünfte ab – und voi­là: du hast soeben dein frei verfügbares Einkommen berechnet!
                        </li>
                    </ol>
                </p>

                <p>
                    <h4>Ein Beispiel gefällig?</h4>
                    Gehen wir das anhand eines Beispiels durch: Nehmen wir eine beliebige Person, sagen wir den
                    32-jährigen Eduard aus Baden, und führen für ihn eine Haushaltsrechnung durch. Eduard ist seit
                    längerem bei einem größeren österreichischen Unternehmen angestellt und verdient derzeit 2100 €
                    netto pro Monat. Hinzu kommt die ebenfalls monatliche Überweisung von 200 € – ein Geschenk, das
                    Eduard zu seinem 18. Geburtstag von einer Großtante gemacht wurde, die – wie man sich auf
                    Familienfeiern hinter vorgehaltener Hand erzählt - durch die strategische Heirat eines betagten
                    Großindustriellen aus altösterreischischem Adel in den Besitz eines beträchtlichen Vermögens
                    gelangte.
                </p>

                <p>
                    Weil die Freigebigkeit von Großtanten bekanntermaßen Grenzen hat und “der Bub” lernen muss, auf
                    eigenen Beinen zu stehen, werden diese Zahlungen mit Eduards 35. Geburtstag jedoch endgültig
                    eingestellt. Bis dahin belaufen sich seine monatlichen Einkünfte also auf 2300 €.
                </p>

                <p> Seine monatlichen Ausgaben setzen sich aus</p>
                <ul>
                    <li>
                        <b>620 €</b> für Miete inkl. Strom und Gas
                    </li>
                    <li>
                        <b>200 €</b> für Lebensmittel
                    </li>
                    <li>
                        <b>50 €</b> für den Kombivertrag für Handy und Internet
                    </li>
                    <li>
                        <b>365 €</b> für die Jahreskarte der Wiener Linien
                    </li>
                    <li>
                        <b>120 €</b> für sein Hobby
                    </li>
                </ul>
                <p>
                    was{" "}
                    <b>
                        <u>1355 €</u>
                    </b>{" "}
                    an <b>monatlichen Ausgaben</b> ergibt.
                </p>
                <ul>
                    <li>
                        Im letzten Schritt ziehen wir die <b>Ausgaben</b> von den <b>Einnahmen</b> ab:{" "}
                        <b>
                            <u>2300 - 1355 = 945</u>
                        </b>
                        .
                    </li>
                </ul>

                <p>
                    Eduard kann monatlich also auf einen Betrag von 945 € zurückgreifen, den er nicht für die Deckung
                    seiner laufenden Kosten aufwenden muss. Doch sei gewarnt, Eduard: in nur zwei Jahren werden dir
                    monatlich nur noch 745 € zur Verfügung stehen. Wenn er sich also demnächst eine Eigentumswohnung in
                    Wien anschaffen will, um seiner denunziatorischen Badener Verwandtschaft zu entkommen, muss er
                    diesen Umstand bei der Planung seiner Finanzierung unbedingt bedenken.
                </p>

                <p>
                    Ist das Saldo deiner Haushaltsrechnung positiv, gibt es noch eine zweite Frage, die du vorab
                    beantworten musst: Hast du genug Eigenmittel?
                </p>

                <p>
                    In Österreich sind <b>20 % Eigenmittel verpflichtend</b>. Wenn du dir nicht sicher bist, ob du genug
                    Eigenmittel hast, sprich am besten mit einem unserer Finanzberater. Diese können dir auch dabei
                    helfen, weitere Stolpersteine auf deinem Weg zum Kredit aus dem Weg zu räumen.
                </p>

                <p>
                    Dazu gehören etwa die versteckten Kosten deines Kredits. Du solltest zum Beispiel bedenken, dass die
                    gesamten Kreditkosten aus der Kreditsumme sowie den während der Kreditlaufzeit anfallenden Zinsen
                    sowie der Kreditnebenkosten zusammensetzen.
                </p>

                <p>
                    Wenn du eine Immobilie ins Auge gefasst hast, in der du selbst wohnen willst, kommen neben der
                    monatlichen Rate natürlich auch noch monatliche Kosten wie Betriebskosten und die Kosten für
                    Energie, das heißt Strom, Heizung und Wasser und im Falle eines Einfamilienhauses auch noch einige
                    zusätzlich Nebenkosten pro Jahr hinzu.
                </p>
            </Article>

            {/*TODO: Linking to affordability Calculator, Linking to other articals*/}

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData
                page={key}
                heading={question}
                description={answer}
                datePublished="2022-09-12"
                dateModified="2022-09-12"
            />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
